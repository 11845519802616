// ======================================================================
// 06-components - Split
// ======================================================================

.split {
  @include media("screen", "<=tablet") {
    grid-column-gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: "right" "left";
  }
  display: grid;
  grid-column-gap: rem(72px);
  grid-row-gap: rem(24px);
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "right left";

  figure {
    display: block;
    line-height: 0;
    margin: rem(24px) 0 rem(24px) 0;
  }
}

.split-left {
  grid-area: left;
}

.split-right {
  grid-area: right;

  h1,
  p {
    @include minbreak($bp-large) {
      padding: 0;
    }
  }
}
