// ======================================================================
// 06-components - Contact
// ======================================================================

.contact {
  @include media("screen", ">=tablet") {
    margin-top: rem(24px);
  }

  @include media("screen", "<=tablet") {
    text-align: center;
    margin-bottom: rem(36px);
  }

  a {
    @include type-free(16px, 0, 0, 21px, true);
    display: inline-block;
    padding: rem(8px);
    background-color: $jet;
    color: $bright;
    font-weight: $semibold;
    text-transform: uppercase;

    &:focus,
    &:hover {
      background-color: $gray;
      text-decoration: none;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: rem(8px);
    }
  }
}

.booking {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: rem(48px) 0;
  
  .cta-btn {
    margin: 0 rem(12px);
  }
}
