// ======================================================================
// 06-components - Side Navigation
// ======================================================================

.side-nav-left,
.side-nav-right {
  position: fixed;
  width: rem(20px);
  height: rem(20px);
  top: 50%;
}

.side-nav-left {
  left: rem(20px);
}

.side-nav-right {
  right: rem(20px);
}
