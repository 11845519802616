// ======================================================================
// 04-elements - Logo
// ======================================================================

#logo {
  grid-area: logo;
  display: grid;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  font-weight: $semibold;
  text-transform: uppercase;

  a {
    display: inline-block;

    background-color: $jet;
    color: $bright;

    &:hover {

    }
  }
}
