// ======================================================================
// 02-tools - Media
// ======================================================================

%fluidimg {
  width: 100%;
  height: auto;
}

%embed {
  @include ioe;
  margin: 0 0 rem(24px);
}

%fancycaption {
  display: inline-block;
  margin: 0;
  background-color: $jet;
  color: $bright;
  padding: rem(8px) rem(16px);
  font-weight: $semibold;
}
