// ======================================================================
// HTML
// ======================================================================





html {
  @include rem-baseline(100%);
  @include font-smoothing(on);
  line-height: $line-height;
  color: $font-color;
  font-family: $font-stack;
  font-weight: $font-weight;
}

body {
  @include baseline;
}

// ======================================================================
// Headings
// ======================================================================

h1 {
  @include type-free(38px, 24px, 24px, 48px, true);
}

h2 {
  @include type-free(28px, 24px, 24px, 48px, true);
}

h3 {
  @include type-free(21px, 24px, 24px, 24px, true);
}

h4,
h5 {
  @include type-free(16px, 24px, 0, 24px, true);
}

h1,
h2,
h3,
h4,
h5 {
  color: $bright;
  font-family: $heading-stack;
  font-weight: $heading-weight;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 100;
}

// ======================================================================
// Paragraphs
// ======================================================================

p {
  @include type-free($base-font-size, 0, 24px, 24px, true);
  hanging-punctuation: first;
}

// ======================================================================
// Figure
// ======================================================================

figcaption {
  @include type-free($base-font-size, 12px, 48px, 24px, true);
  text-transform: uppercase;
}

// ======================================================================
// Lists
// ======================================================================

ol,
ul {
  @include type-free($base-font-size, 0, 24px, 24px, true);
  color: $gray;
  list-style-position: outside;
  padding: 0;

  p {
    color: $gray;
  }

  ol,
  ul {
    margin-bottom: 0;
    margin-top: 0;
  }
}

// dl {
//   // @include type-modular(zeta);
//   @include type-free($base-font-size, 0, 24px, 24px, true);
//
//   dt {
//     font-weight: $semibold;
//   }
//
//   dd {
//     font-style: italic;
//   }
// }
// ======================================================================
// Tables
// ======================================================================
// table {
//   @include table($default-table);
// }
// ======================================================================
// Quotes
// ======================================================================

blockquote {
  margin: 0;
  @include type-free(21px, 24px, 24px, 21px * 1.5, true);
  font-family: $georgia;
  padding: rem(12px) rem(24px);
  background-color: #eee;
}

blockquote > p {
  padding: 0;
}

// ======================================================================
// Misc
// ======================================================================

a {
  color: $link;
  text-decoration: none;
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;

  &:hover {
    color: $link-hover;
    text-decoration: $link-hover-decoration;
  }

  &:active,
  &:focus {
    outline: none;
    color: $link-hover;
  }
}

hr {
  border: 0;
  border-top: $hr-style;
  display: block;
  height: 1px;
  padding: 0;
  margin-bottom: rem(24px);
}

sub,
sup {
  position: relative;
  top: -0.4rem;
  vertical-align: baseline;
}

sub {
  top: 0.4rem;
}

b {
  line-height: 1;
}

small {
  line-height: 1;
}

strong {
  font-weight: $semibold;
}

cite,
dfn,
em,
q {
  line-height: $line-height;
}

code {
  @include type-free($base-font-size, 0, 24px, 24px, true);
}

kbd {
  margin-bottom: 1px;
  padding: 0;
  position: relative;
  top: -1px;
}

pre {
  @include type-free($base-font-size, 0, 24px, 24px, true);
  @include rem(padding, 12px);
  background-color: $jet;
  color: $bright;
  font-weight: $bold;
  font-family: $terminal;
  white-space: pre;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: pre\9;
}
