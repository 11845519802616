// ======================================================================
// 04-elements - Buttons
// ======================================================================

.menu-butt, .cta-btn {
  @include button($inverted-button);
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;

}
.menu-butt {
  @include button($inverted-button);
  transition: color 0.4s ease-in-out, background-color 0.4s ease-in-out;

  @include media("screen", ">=1230px") {
    display: none;
  }
  

}


