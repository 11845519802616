// ======================================================================
// 04-elements - Quote
// ======================================================================

figure.qoute {
  margin-bottom: 40px;
  figcaption {
    position: relative;
    bottom: 0;
  }
  blockquote {
    margin-bottom: 0;
  }
}
