// ======================================================================
// 06-components - Footer
// ======================================================================

footer {
  display: grid;
  grid-template-rows: auto auto;
  align-items: normal;
  padding: rem(48px) 0 0 0;
  a {
    color: $gray;

    &:hover {
      text-decoration: none;
    }
  }

  @include type-free(14px, 0, 0, 24px, true);

  p {
    margin-bottom: 0;
  }
  text-transform: uppercase;
  letter-spacing: 1px;
}

.foot-logo {
  display: grid;
  justify-content: center;
  align-items: center;

  figure {
    margin: 0 auto;
    width: 100%;
    max-width: rem(200px);
    height: auto;
    svg {
      width: 100%;
      height: auto;
    }
  }
}

.footer-top {
  padding: 0 rem($gutter);

  p {
    text-align: center;
    margin-top: rem(24px);
  }
}

.footer-bottom {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;

  @include media("screen", "<968px") {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding: rem(8px) 0 rem(8px) 0;
  }
  align-items: center;
  justify-content: center;
  padding: 0 rem($gutter);
  width: 100%;
  bottom: 0;
  height: rem(64px);
}

.hashandsalt {
  text-align: right;
  @include media("screen", "<968px") {
    text-align: center;
  }
}

.foot-left.menu {
  display: block;
  justify-content: flex-start;
  grid-template-rows: rem(64px);
  ul {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    @include media("screen", "<968px") {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.foot-right {
  display: grid;
  align-items: flex-end;
  justify-content: flex-end;
  @include media("screen", "<968px") {
    justify-content: center;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    column-gap: 20px;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
  }

  a {
    display: block;
    width: 20px;
    height: 20px;
  }

  svg {
    width: 100%;
    width: 20px;
    height: 20px;
  }
}

.foot-middle {
  text-align: center;
}

.hs-heart {
  color: #f53580;
}

.button-bar {
  .cta-btn {
    display: block;
    width: 100%;
    max-width: 200px;
    clear: both;
  }
  display: grid;
  justify-content: center;

  @include media("screen", ">590px") {
    display: flex;
    justify-content: center;
    padding: rem(48px) 0;
  }
}
