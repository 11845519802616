// ======================================================================
// 06-components - Page
// ======================================================================

#page {
  display: grid;
  height: 100%;
  width: 100%;
  // align-items: flex-start;
  grid-template-rows: rem(120px) 1fr auto;
  grid-template-columns: auto;
  grid-template-areas: "siteheader" "middle" "sitefooter";
}

#site-header {
  grid-area: siteheader;
}

#middle {
  grid-area: middle;
}

#site-footer {
  grid-area: sitefooter;
}
