// ======================================================================
// 04-elements - Accordion
// ======================================================================
details {
  border: 1px solid $bright;
  padding: 0 1rem;

  color: $font-color;
}
details + details {
  border-top: none;
}
details[open] {
  padding-bottom: 1em;
}
summary {
  padding: 1rem 2em 1rem 0;
  color: $bright;
  cursor: pointer;
}
