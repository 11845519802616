// ======================================================================
// 06-components - About
// ======================================================================

.sig {
  overflow: hidden;


}

// .self-portrait {
//   display: grid;
//   align-items: flex-end;
//   justify-content: flex-end;

// }

// .home-section {
//   margin-bottom: rem(96px);
// }

.about-section, .portfolio-section,  {
  padding: rem(96px) 0;
}

.about-section{
  border-top: 1px solid $gold;


}

.services-section {
  border-bottom: 1px solid $gold;
  margin: 0 0 rem(96px) 0;
}