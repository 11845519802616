// ======================================================================
// 06-components - Menu
// ======================================================================




#site-header.menu {
  grid-area: menu;
}

#site-header {
  .menu ul {
    @include media("screen", "<=1230px") {
      display: none;
    }
  }
}

.menu {
  display: grid;

  align-items: center;
  justify-content: flex-end;
  grid-template-rows: rem(120px);


  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

   

    li:first-child {
      a {
        margin: 0;
      }
    }
  }

  a {
    @include type-free(16px, 0, 0, 1.2, false);
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 0 0 rem(16px);
    display: inline-block;
    color: $bright;

    &:hover {
      color: $link-hover;
      text-decoration: none;
    }

    &:active,
    &:focus {
      outline: none;
      color: $link-hover;
    }
  }
}

.site-menu {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: $jet;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: none;
  opacity: 0;

  &.site-menu-inactive {
    animation: menufadeout 0.4s;
    opacity: 0;
  }

  &.site-menu-active {
    display: grid;
    animation: menufadein 0.4s;
    opacity: 1;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  a {
    @include type-free(22px, 0, 0, 22px * 1.5, true);
    text-transform: uppercase;
    letter-spacing: 1px;

    display: inline-block;
    color: $gray;

    &:hover {
      text-decoration: none;
      color: $bright;
    }

    &:active,
    &:focus {
      outline: none;
      color: $bright;
    }
  }
}

@keyframes menufadeout {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes menufadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
