// ======================================================================
// 06-components - Showtime
// ======================================================================

.show-time {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  overflow: hidden;
}

.home .show-time {
  position: relative;
  // height: calc(100vh - rem(120px));
  height: 50vh;
}

.showtime-item {
  position: absolute;
  width: 100%;
  height: 100%;

  &.cover img {
    object-fit: cover;
  }

  &.contain img {
    object-fit: contain;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.show-time-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-column-gap: rem(24px);
  grid-row-gap: rem(24px);
  align-items: center;
  justify-content: center;

  @include media("screen", ">=tablet", "<=desktop", "retina2x") {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
  @include media("screen", ">=tablet") {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;

    .info {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }
  @include media("screen", ">=tablet", "<=desktop", "retina2x") {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    .info {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }
  @include media("screen", ">=desktop") {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;

    .info {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }
}

.strapline {
  // position: absolute;
  // z-index: 10;
  // top: 0;
  // left: 0;
  // display: grid;

  // width: 100%;
  // height: 100%;
  // grid-template-rows: 1fr 1fr;

  h1,
  .h1 {

    text-transform: none;
  }
}

.tagline {
  text-align: center;
  // display: grid;
  // justify-content: center;
  // align-items: center;
  // .h1,
  // h1 {
  //   text-align: center;
  //   // padding: 0 rem(48px);
  // }
  // @include media("screen", ">=1225px") {
  //   justify-content: flex-start;
  //   align-items:flex-start;
  //   grid-template-columns: 1fr 3fr;
  //   .h1,
  //   h1 {
  //     text-align: left;
  //   }
  // }
}

.mission {
  // display: grid;

  // justify-content: center;
  // align-items: center;
  // .h1,
  // h1 {
  //   text-align: right;
  //   @include media("screen", "<=1225px") {
  //     text-align: center;
  //   }
  //   padding: 0 rem(48px);
  //   grid-column-start: 2;
  // }
  // @include media("screen", ">=1225px") {
  //   justify-content: flex-end;
  //   align-items: flex-end;
  //   grid-template-columns: 2fr 1fr;
  // }
text-align: center;

display: grid;
justify-content: center;

.h1, h1 {
  max-width: rem(600px);
}

}

.info {
  h1 {
    text-align: center;
  }
}
