// ======================================================================
// 02-tools - Grid
// ======================================================================
.grid {
    display: grid;
    row-gap: rem(24px);
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-template-rows: auto;
    margin-bottom: rem(48px);
  
    @include media("screen", ">=tablet") {
      column-gap: rem(48px);
      row-gap: rem(48px);
    }
  }
  
  .column {
    grid-column: col-start / span 12;
    @include media("screen", ">=tablet") {
      grid-column: span var(--span);
    }
  }
  