// ======================================================================
// 04-elements - Media
// ======================================================================





@include fontface("Parisienne", "Parisienne-Regular", 400, normal, woff2);



figure {
  padding: 0;
  margin: 0;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  figcaption {
    @extend %fancycaption;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      line-height: 0;
      margin: 0 0 rem(24px) 0;
    }
  }
}

img {
  width: 100%;
  height: auto;
}

.video-container {
  width: 100%;
  height: auto;
  margin: 0 auto;
  line-height: 0;

  video {
    object-fit: contain;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &.full-video {
    height: 100%;
    max-width: 100%;
    position: absolute;

    video {
      object-fit: cover;
    }
  }
}

.videopost {
  @include ioe;
}

.aff {
  margin: 0 0 0 0;
  padding: rem(48px) 0;
  border-bottom: 1px solid $gold;
  list-style: none;
  display: inline;

  @include media("screen", ">=tablet") {
    display: flex;
    column-gap: rem(12px);
    row-gap: rem(12px);
    align-items: center;
    justify-content: center;
  }

  li {
    display: grid;
    align-items: center;
    justify-content: center;
    @include media("screen", "<=tablet") {
      justify-content: center;
      margin: rem(24px);
    }
  }
}

.mtog {
  width: 150px;
  height: auto;
}

.swpp {
  width: 240px;
  height: auto;
}

.masonary {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: 1140px;
}
.masonary-item {
  position: absolute;
}
